
import { defineComponent } from 'vue';
import { message } from 'ant-design-vue';
import router from '@/router';
import GerenteAutenticacao from '../../gerentes/GerenteAutenticacao';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';

export default defineComponent({
  name: 'AmbienteNaoAutenticado',
  components: {

  },
  setup() {
    function voltar() {
      router.back();
    }

    async function deslogar() {
      const servicoSistema = new ServicoSistema();
      const gerenteAutenticacao = new GerenteAutenticacao();
      const retornoAutenticacao = await servicoSistema.deslogar();
      if (!retornoAutenticacao.autenticado) {
        gerenteAutenticacao.removerDadosAutenticacao();
        router.push({ name: 'Login' });
      } else {
        message.warn(retornoAutenticacao.mensagem);
      }
    }

    return {
      voltar,
      deslogar,
    };
  },
});
