
import { defineComponent, reactive } from 'vue';
import InlineSvg from 'vue-inline-svg';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '../components/Icone.vue';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import router from '@/router';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';

export default defineComponent({
  name: 'EsqueciMinhaSenha',
  components: {
    InlineSvg,
    Icone,
  },
  setup() {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();

    const state = reactive({
      chaveParceiroAmbiente: '',
      chaveParceiro: '',
      email: '',
      carregando: false,
    });

    async function enviarConfirmacaoEmail() {
      if (state.email === '') {
        apresentarMensagemAlerta('E-mail não preenchido!');
        return;
      }

      if (state.chaveParceiro === '' || !UtilitarioGeral.validaNumerico(state.chaveParceiro)) {
        apresentarMensagemAlerta('Código do contratante não informado!');
        return;
      }
      state.carregando = true;

      const servicoSistema = new ServicoSistema();
      const retorno = await servicoSistema.enviarEmailRedefinicaoSenha(state.email, Number(state.chaveParceiro));
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso('Entre em seu e-mail para continuar a redefinição de senha!');
        router.push({ name: 'Login' });
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
      state.carregando = false;
    }

    return {
      state,
      telaBase,
      storeSistema,
      enviarConfirmacaoEmail,
    };
  },
});
