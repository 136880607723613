
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useRoute } from 'vue-router';
import InlineSvg from 'vue-inline-svg';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '../components/Icone.vue';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import router from '@/router';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';

export default defineComponent({
  name: 'RedefinirSenha',
  components: {
    InlineSvg,
    Icone,
  },
  setup() {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const gerenteAutenticacao = new GerenteAutenticacao();
    const route = useRoute();

    const state = reactive({
      contratante: '',
      codigoAcesso: '',
      senha: '',
      confirmacaoSenha: '',
      carregando: false,
    });

    async function redefinirSenha() {
      if (state.senha !== state.confirmacaoSenha) {
        apresentarMensagemAlerta('A senha de confirmação não é igual a senha informada!');
        return;
      }

      state.carregando = true;
      const servicoSistema = new ServicoSistema();
      const retorno = await servicoSistema.redefinirSenha(state.codigoAcesso, state.senha);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso('Senha redefinida com sucesso!');
        router.push({ name: 'Login' });
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
      state.carregando = false;
    }

    onBeforeMount(async () => {
      state.carregando = true;
      state.contratante = String(route.params.contratante);
      state.codigoAcesso = String(route.params.codigoAcesso);
      const servicoSistema = new ServicoSistema();
      const retorno = await servicoSistema.validarRedefinicaoSenha(state.codigoAcesso);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemAlerta('O pedido de recuperação não vale mais!');
        router.push({ name: 'Login' });
      }
      state.carregando = false;
    });

    return {
      state,
      telaBase,
      storeSistema,
      redefinirSenha,
    };
  },
});
